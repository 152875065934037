.number-wheel {
    .range-picker {
        height: 50px;
        padding: 10px;

        input[type="range"] {
            -webkit-appearance: none;
            height: 7px;
            background: grey;
            border-radius: 5px;
            background-image: linear-gradient(#1F2667, #1F2667);
            background-repeat: no-repeat;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: #1F2667;
                cursor: pointer;
                box-shadow: 0 0 2px 0 #555;
                transition: 'background 0.3s ease-in-out';
            }

            &::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }

            &:not(:disabled) {
                &::-webkit-slider-thumb:hover {
                    box-shadow: #6a94d450 0px 0px 0px 8px;
                }

                &::-webkit-slider-thumb:active {
                    box-shadow: #6a94d450 0px 0px 0px 11px;
                    transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                        left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                        bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                }
            }

            &:disabled {
                cursor: not-allowed;

                &::-webkit-slider-thumb {
                    cursor: not-allowed;
                }
            }
        }
    }

    .playButton {
        margin: 0 auto;
        background: #1F2667;
        padding: 5px;
        border: 0px;
        border-radius: 5px;
        font-size: 1.9em;
        color: #fff;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        width: 250px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;

        .playButton-icon {
            padding-left: 20px;
            color: #fff;
        }

        &.playButton-disabled {
            cursor: not-allowed;
        }
    }

    .doors {
        display: inline-flex;

        .door {
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            background: #fafafa;
            overflow: hidden;
            border-radius: 5px;
            margin: 5px;
            color: #1F2667;

            .boxes {
                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}