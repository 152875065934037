.App {
  text-align: center;
}

.nav-link {
  font-weight: 500;
}

.link-dark {
  color: #1F2667 !important;
}

.cc-h1 {
  font-size: 1.7rem;
  background: -webkit-linear-gradient(45deg, #1F2667, #3a46af 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #333;
  display: inline-block;
  font-weight: 700;
}

@media (min-width: 1200px) {

  h2,
  h2 {
    font-size: 1.1rem;
  }
}

.img-fluid-custom {
  width: 90% !important;
}


:root {
  --bs-link-color: #1F2667 !important;
}

.cc-link {
  text-decoration: none;
  font-size: 1.5rem;
}

.btn-primary {
  --bs-btn-bg: #1F2667;
  --bs-btn-border-color: #1F2667;
}

.link-cc {
  color: #1f2667;
  font-weight: 600;
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

footer {
  margin-top: auto;
  padding-top: 1rem !important;
}

.group-header {
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.1rem;
}