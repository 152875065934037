.wheel {
    .wheel-canvas-wrapper {
        position: relative;
        overflow: hidden;

        &:after {
            position: absolute;
            content: '';
            background: url('../../content/images/spinner.svg') no-repeat;
            width: 70px;
            height: 67px;
            background-size: 70px;
            top: 17%;
            left: 23%;
            transform: translateY(-50%) translateX(-100%);
        }

        .wheel-canvas {
            position: relative;
            overflow: hidden;
            transition: all ease 5s;
        }

        .playButton {
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background: none;
            border: 0px;
            font-size: 2.5em;
            color: #1F2667;
        }
    }

    .wheel-table {
        background-color: #eee;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        border-radius: 8px;
        max-width: 400px;
        padding-top: 10px;
        padding-bottom: 10px;

        .wheel-table-intro {
            background-color: #fff;
            border-radius: 8px;
            height: 56px;

            .winner-label {
                color: #fff;
                font-weight: 700;
            }
        }

        .wheel-table-entry-box {
            background-color: #fff;
            border-radius: 8px;
            width: auto;
            overflow-y: auto;
            max-width: 100%;
            max-height: 200px;

            .entry-item,
            .entry-item-dark {
                min-height: 24px;
            }

            .entry-item {
                display: block;
                text-align: start;
            }

            .entry-item-dark {
                display: block;
                text-align: start;
                background-color: #F1F2F5;
                border-radius: 5px;
            }

            .remove-entry {
                margin-top: 5px;
                cursor: pointer;
                width: 13px;
                height: 13px;
                color: #4e4c4c;
                float: right;
                padding-right: 10px;

                &:hover {
                    color: red;
                    transition: color 0.5s ease;
                }
            }
        }

        .wheel-input {
            width: auto;

            textarea {
                overflow-y: auto;
                height: 300px;
                resize: none;
            }
        }

        .switch+.switch {
            margin-top: 10px;
        }
    }

    .wheel-canvas-initial {
        -webkit-animation: spin 10s linear infinite;
        -moz-animation: spin 10s linear infinite;
        animation: spin 10s linear infinite;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .modalx {
        position: fixed;
        z-index: 3;
        padding-top: max(300px, 20%);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);

        .modal-contentx {
            background-color: #fefefe;
            margin: auto;
            padding: 20px;
            border: 1px solid #888;
            border-radius: 8px;
            width: max(25rem, 30%);

            .winner-label-modal {
                color: black;
                font-weight: 700;
            }

            .close {
                color: #aaaaaa;
                float: right;
                font-size: 28px;
                font-weight: bold;

                &:hover,
                :focus {
                    color: #000;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
}