.switch {
    display: flex;
    align-items: center;

    margin-left: 1rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .switch-label {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin-left: auto;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked+.slider {
                background-color: #1F2667;

                &:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                }
            }

            &:focus+.slider {
                box-shadow: 0 0 1px #1F2667;
            }
        }


        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;

            &.round {
                border-radius: 34px;

                &:before {
                    border-radius: 50%;
                }
            }

            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
            }
        }
    }
}