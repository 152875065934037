.menu {
    width: 100%;

    :first-child.nav-item {
        flex: 1 1 0;
    }

    .list-group span {
        margin-left: 12px;
    }

    .nav-link {

        &:hover {
            color: #2a37a7 !important;
            transition: color 0.5s ease;
        }
    }
}